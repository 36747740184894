import React, { Component } from 'react';
import "./style.css";

class Sobre extends Component {
    render() { 
        return (
            <section className="sobre">
                <h1 className="sobre-titulo">Sobre</h1>
                <p className="sobre-texto">
                    Com larga experiência no mercado, a Sattra é uma empresa de tecnologia com expertise no
                    desenvolvimento de projetos voltados para segurança, controle e automação. <br /><br />

                    Sistemas inteligentes, baseados em imagens, destacam-se pelo atendimento às exigências da
                    Receita Federal do Brasil para os Portos, Terminais Logísticos alfandegados e Redex pelo
                    atendimento às legislações aduaneiras brasileiras, COANA/COTEC e ao Código Internacional
                    paraa Proteção de Navios e Instalações Portuárias (ISPS-CODE). <br /><br />

                    A Sattra também é reconhecida pela capacidade de adequar seus projetos às mais variadas
                    empresas, públicas ou privadas nos setores comercial, industrial, agroindustrial, transporte e
                    governo. <br /><br />

                    Além de desenvolver tecnologias e sistemas a SATTRA atua na elaboração de projetos,
                    implantação e manutenção, oferecendo também suporte e capacitação ao longo doprocesso. <br /><br />
                    
                    A SATTRA trabalha com soluções inovadoras e mantém parcerias com os grandes players
                    do mercado garantindo assim o melhor custo benefício em seus projetos aliados as melhores
                    tecnologias, nacionais e internacionais. <br /><br />
                   
                    Para prover as melhores soluções, a SATTRA se tornou uma empresa que abrange diversos
                    segmentos em segurança, exercendo assim funções na área de Softwares, que se destaca por
                    seus sistemas de análise de imagens (OCR) bem como atua na instalação dos equipamentos de
                    controle e segurança mais avançados existentes. <br /><br />

                    <div className="sobre-lista">
                        • Processo de alfandegamento da área de acordo com as normas vigentes da Receita Federal;<br/>
                        • Instruções Normativas 682/2006 (disciplina a forma pela qual a Receita Federal ira exercer o controle informatizado do Terminal e aplica-se a Portos e Aeroportos;<br/>
                        • Sistema Aduaneiro;<br/>
                        • Sistema de vigilância eletrônica;<br/>
                        • Sistema de controle de pedestre;<br/>
                        • Sistema perimetral;<br/>
                        • Sistema Automatizado LPR \ ACCR;<br/>
                        • Atos Declaratórios ADE SRF 034/2004, ADE Cotec/ Coana 001/2006, ADE Cotec/ Coana 002/2006.<br/>
                    </div>
                </p>
                <ul className="sobre-tabela">
                    <li>
                        <h2 className="sobre-titulo2">Missão</h2>
                        <p>
                            Desenvolver soluções integradas e customizadas em segurança, buscando excelência nos
                            serviços, satisfação total dos clientes e valorização dos colaboradores, sempre com postura ética
                            e responsabilidade ambiental e social.
                        </p>
                    </li>
                    <li>
                        <h2 className="sobre-titulo2">Visão</h2>
                        <p>
                            Ser uma empresa reconhecida nacional e internacionalmente oferecendo soluções tecnológicas
                            de ponta, em conformidade com os mais altos padrões mundiais de qualidade e eficiência do
                            mercado.
                        </p>
                    </li>
                    <li>
                        <h2 className="sobre-titulo2">Valores</h2>
                        <p>
                            Comprometimento, transparência, competência, qualidade, respeito e ética.
                        </p>
                    </li>
                </ul>
                {/* <img src={i1} className="sobre-image" /> */}
            </section>
        );
    }
}
 
export default Sobre;