import React, { Component } from 'react';
import "./style.css";

class Servico extends Component {
    render() { 
        return (
            <section id="servicos">
                <h1 className="servicos-titulo">Serviços</h1>
                <ul>
                    <li>
                        <p>
                            A <em className="negrito">SATTRA SOLUÇÕES EM TECNOLOGIA LTDA</em> possui grande experiência na elaboração, gestão e execução 
                            de projetos em recintos alfandegados, também na implementação de soluções customizadas para as necessidades que o mercado 
                            demanda aos seus clientes. A empresa realiza assessoramento e estudos para atividades relacionadas à implantação das normas 
                            orientadas pelo ISPS CODE.<br /><br />
                            A SATTRA possui profundo conhecimento das normas da Autoridade Marítima Brasileira e atua para a regularização, legalização e 
                            alfandegamento de recintos.<br /><br />
                            A empresa possui equipe de desenvolvimento e tecnologia próprias para a solução de OCR assim como uma plataforma exclusiva de 
                            automação dos gates com sistema de controle de acesso de pessoas e veículos integradas ao OCR e CFTV.<br /><br />
                            Soluções voltadas exclusivamente para automação e redução de custos operacionais.<br /><br />

                            <div className="servicos-lista">
                                Projetos: <br />
                                · Projetos de Alfandegamento (RFB)<br /><br />

                                Consultorias:<br />
                                · ISPS-CODE<br />
                                · Operações Portuárias<br />
                                · Instalação Portuárias
                            </div>
                        </p>
                    </li>
                </ul>
            </section>
        );
    }
}
 
export default Servico;