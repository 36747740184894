import React from 'react';
import Slider from "../../components/Slider/";
import IndexVideo from "../../components/IndexVideo";
import Clientes from "../../components/Clientes";
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import "./style.css";


function Main () {
    return (
        <section className="home-page">
            <NavBar />
            <div className="home-slider">
                <Slider />
            </div>
            <Clientes />
            <Footer />
        </section>
    );
}

export default Main;