import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.css";
import i1 from "../../assets/imgs/desenhos/desenho1.jpg";
import i2 from "../../assets/imgs/desenhos/desenho3.jpg";
import i3 from "../../assets/imgs/desenhos/desenho4.jpg";
import i4 from "../../assets/imgs/desenhos/desenho8.jpg";
import i5 from "../../assets/imgs/desenhos/desenho10.jpg";
import i6 from "../../assets/imgs/home/porto.jpg";
import i7 from "../../assets/imgs/home/navio.jpg";
import i8 from "../../assets/imgs/home/catraca.jpg";

const images = [
  {
    original: i1,
    thumbnail: i1,
  },
  {
    original: i2,
    thumbnail: i2,
  },
  {
    original: i3,
    thumbnail: i3,
  },
  {
    original: i4,
    thumbnail: i4,
  },
  {
    original: i5,
    thumbnail: i5,
  },
  {
    original: i6,
    thumbnail: i6,
  },
  {
    original: i7,
    thumbnail: i7,
  },
  {
    original: i8,
    thumbnail: i8,
  },
];



class Slider extends Component {
    render() {
        return <ImageGallery items={images} autoPlay={true} />;
    }
}
 
export default Slider;