import React, { Component } from "react";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.css";

import s1 from "../../assets/imgs/slide/slide1.jpg";
import s2 from "../../assets/imgs/slide/slide2.jpg";
import s3 from "../../assets/imgs/slide/slide3.jpg";
import s4 from "../../assets/imgs/slide/slide4.jpg";
import s5 from "../../assets/imgs/slide/slide5.jpg";
import s6 from "../../assets/imgs/slide/slide6.jpg";
import s7 from "../../assets/imgs/slide/slide7.jpg";
import s8 from "../../assets/imgs/slide/slide8.jpg";
import s9 from "../../assets/imgs/slide/slide9.jpg";
import s10 from "../../assets/imgs/slide/slide10.jpg";
import s11 from "../../assets/imgs/slide/slide11.jpg";
import s12 from "../../assets/imgs/slide/slide12.jpg";
import s13 from "../../assets/imgs/slide/slide13.jpg";
import s14 from "../../assets/imgs/slide/slide14.jpg";
import s15 from "../../assets/imgs/slide/slide15.jpg";
import s16 from "../../assets/imgs/slide/slide16.jpg";
import s17 from "../../assets/imgs/slide/slide17.jpg";
import s18 from "../../assets/imgs/slide/slide18.jpg";
import s19 from "../../assets/imgs/slide/slide19.jpg";
import s20 from "../../assets/imgs/slide/slide20.jpg";

const images = [
  {
    original: s1,
    thumbnail: s1,
  },
  {
    original: s2,
    thumbnail: s2,
  },
  {
    original: s3,
    thumbnail: s3,
  },
  {
      original: s4,
      thumbnail: s4,
  },
  {
      original: s5,
      thumbnail: s5,
  },
  {
      original: s6,
      thumbnail: s6,
  },
  {
      original: s7,
      thumbnail: s7,
  },
  {
      original: s8,
      thumbnail: s8,
  },
  {
      original: s9,
      thumbnail: s9,
  },
  {
      original: s10,
      thumbnail: s10,
  },
  {
      original: s11,
      thumbnail: s11,
  },
  {
      original: s12,
      thumbnail: s12,
  },
  {
      original: s13,
      thumbnail: s13,
  },
  {
      original: s14,
      thumbnail: s14,
  },
  {
      original: s15,
      thumbnail: s15,
  },
  {
      original: s16,
      thumbnail: s16,
  },
  {
      original: s17,
      thumbnail: s17,
  },
  {
      original: s18,
      thumbnail: s18,
  },
  {
      original: s19,
      thumbnail: s19,
  },
  {
      original: s20,
      thumbnail: s20,
  },
];

class IndexVideo extends Component {
  render() {
    return (
      <div className="div-video">
        <div className="slide-home" >
          <ImageGallery items={images} />
        </div>
      </div>
    );
  }
}

export default IndexVideo;
