import React, { Component } from "react";
import "./style.css";
import i1 from "../../assets/imgs/Clientes/Poly.svg";
import i2 from "../../assets/imgs/Clientes/PortoItajai.svg";
import i3 from "../../assets/imgs/Clientes/Camera.svg";
import i4 from "../../assets/imgs/Clientes/Logibras.svg";
import i5 from "../../assets/imgs/Clientes/Suzano.svg";

class Clientes extends Component {
  render() {
    return (
      <div className="div-clientes">
        <h1 className="titulo-clientes">Principais Clientes</h1>
        <ul className="lista-clientes">
          <li className="clientes" >
            <section className="cliente">
              <img src={i1} alt="Logo da empresa Poly" className="cliente-image-poly" />
            </section>
          </li>
          <li className="clientes" >
            <section className="cliente">
              <img src={i2} alt="Logo do Porto de Itajaí" className="cliente-image-porto" />
            </section>
          </li>
          <li className="clientes" >
            <section className="cliente">
              <img src={i3} alt="Logo da câmera de vereadores de Itajaí" className="cliente-image-camera" />
              <p className="camera-texto">Câmera de Vereadores de Itajaí</p>
            </section>
          </li>
          <li className="clientes" >
            <section className="cliente">
              <img src={i4} alt="Logo da empresa Logibras" className="cliente-image-logibras" />
            </section>
          </li>
          <li className="clientes" >
            <section className="cliente">
              <img src={i5} alt="Logo da empresa Suzano" className="cliente-image-suzano" />
            </section>
          </li>
        </ul>
        <ul className="lista-clientes2">
          <ul>
            <li className="clientes" >
              <section className="cliente">
                <img src={i1} alt="Logo da empresa Poly" className="cliente-image-poly" />
              </section>
            </li>
            <li className="clientes" >
              <section className="cliente">
                <img src={i2} alt="Logo do Porto de Itajaí" className="cliente-image-porto" />
              </section>
            </li>
            <li className="clientes" >
              <section className="cliente">
                <img src={i3} alt="Logo da câmera de vereadores de Itajaí" className="cliente-image-camera" />
                <p className="camera-texto">Câmera de Vereadores de Itajaí</p>
              </section>
            </li>
          </ul>
          <ul>
            <li className="clientes" >
              <section className="cliente">
                <img src={i4} alt="Logo da empresa Logibras" className="cliente-image-logibras" />
              </section>
            </li>
            <li className="clientes" >
              <section className="cliente">
                <img src={i5} alt="Logo da empresa Suzano" className="cliente-image-suzano" />
              </section>
            </li>
          </ul>
        </ul>
      </div>
    );
  }
}

export default Clientes;
