import React from 'react';
import {HashRouter, Route} from 'react-router-dom';
import Apresentacoes from './pages/apresentacoes';
import PFacial from './pages/PFacial';
import fale_conosco from './pages/fale-conosco';

import Main from "./pages/main"
import Produtos from './pages/produtos';
import Servicos from './pages/servicos';
import sobre from './pages/sobre';

function Routes() {
    return(
        <HashRouter basename="/">
            <Route exact path="/" component={Main} />
            <Route path="/apresentacoes-desenhos" component={Apresentacoes} />
            <Route path="/produtos"  component={Produtos} />
            <Route path="/facial"  component={PFacial} />
            <Route path="/servicos" component={Servicos} />
            <Route path="/sobre" component={sobre} />
            <Route path="/fale-conosco" component={fale_conosco} />
        </HashRouter>
    );
}

export default Routes;