import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.css";
import i1 from "../../../assets/imgs/produtos/camera1.jpg";
import i2 from "../../../assets/imgs/produtos/camera2.jpg";
import i3 from "../../../assets/imgs/produtos/clp1.jpg";
import i4 from "../../../assets/imgs/produtos/Totens.png";
import i5 from "../../../assets/imgs/produtos/cancela1.jpg";
import i6 from "../../../assets/imgs/produtos/iluminacao2.png";
import i7 from "../../../assets/imgs/produtos/iluminacao1.jpg";
import i8 from "../../../assets/imgs/produtos/portal.png"

const images = [
  {
    original: i1,
    thumbnail: i1,
  },
  {
    original: i2,
    thumbnail: i2,
  },
];

const images2 = [
    {
        original: i3,
        thumbnail: i3,
    },
];

const images3 = [
    {
        original: i4,
        thumbnail: i4,
    },
];

const images4 = [
    {
        original: i5,
        thumbnail: i5,
    },
];


const images5 = [
    {
      original: i6,
      thumbnail: i6,
    },
    {
      original: i7,
      thumbnail: i7,
    },
];

const images6 = [
    {
        original: i8,
        thumbnail: i8,
    },
];

class AutoGates extends Component {
    render() { 
        return (
            <section className="autoGates">
                <ul>
                    <h2 className="autoGates-tituloPrinc">Automações de Gates</h2>
                    <li>
                        <h2 className="autoGates-tituloProd">Câmeras</h2>
                        <ImageGallery items={images} showThumbnails={false} showPlayButton={false} />
                        <p className="autoGates-texto-image">
                            Monitoramento e gravação para circuito fechado de TV baseado em rede TCP/IP com 
                            capacidade de controlar e visualizar imagens de câmeras IPs ou analógicas conectadas 
                            aos servidores de vídeo ou codificadores, bem como gravar as imagens para posterior 
                            pesquisa e recuperação seletiva.
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">CLPS</h2>
                        <ImageGallery items={images2} showPlayButton={false} showThumbnails={false} />
                        <p className="autoGates-texto-image">
                            Projetado para atuar em diversos ambientes de uma indústria, suportando sujeira e 
                            poeira, altas temperatura, ruídos e vibrações. Este equipamento é bastante flexível 
                            e possibilita interface com outros dispositivos da fábrica. <br /> <br />

                            CLP – Controlador Lógico Programável, também conhecido como PLC – Programmable Logic 
                            Controller. Trata-se de um computador mais que especial, responsável por automatizar 
                            máquinas e processos industriais
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">Iluminação</h2>
                        <ImageGallery items={images5} showThumbnails={false} showPlayButton={false} />
                        <p className="autoGates-texto-image">
                            Os painéis de iluminação, detectam quando possui um veículo dentro da área desejada. Assim ligando-os, quando não houver veículo 
                            ou não ser necessário o uso de iluminação. Eles se desligam. Gerando assim um menor gasto de energia.
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">Portais</h2>
                        <ImageGallery items={images6} showPlayButton={false} showThumbnails={false} />
                        <p className="autoGates-texto-image">
                            Semelhantes as cancelas, fazendo a liberação e reconhecimento do veículo.
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">Totens</h2>
                        <ImageGallery items={images3} showPlayButton={false} showThumbnails={false} />
                        <p className="autoGates-texto-image">
                            Para maior controle e segurança, o totem contém controle preciso de acesso e permanência. Câmeras para segurança, e identificação 
                            do condutor. Possuindo assistência remota para o condutor. Leitura de código de barras 1D2D, cesto de lixo integrado. <br /><br />

                            Todo o cadastramento é feito de forma automática. Solicitando os dados da pessoa, tirando fotos, entre outros procedimentos de 
                            segurança.
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">Cancelas</h2>
                        <ImageGallery items={images4} showPlayButton={false} showThumbnails={false} />
                        <p className="autoGates-texto-image">
                            As cancelas são um ótimo meio para controle de fluxo e também de acesso. possuindo 
                            motores brushless sem escovas, várias interfaces de IO. Sensores de temperatura e 
                            umidade, telemetria, Comunicação etherenet e serial, juntamente com diversos modelos 
                            de braços. <br /><br />

                            Faz todo o processo em conjunto com o software, liberando a entrada do veículo, quando permitido.
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">Semáforos</h2>
                        <p>
                            Funciona igual o semáforo de trânsito. Utilizado para maior controle de tráfico. Possibilitando alterar o tempo de espera de 
                            cada veículo. Funciona em complemento com as cancelas, abrindo e fechando elas automaticamente.
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">Sistema (Software)</h2>

                        <p>
                            Software que controla todos os processos de entrada e saída do local. Verificando a 
                            permissão, tirando fotos, escaneando, registrando dados, fazendo a interação entre os 
                            vários sistemas de reconhecimento (placas, containers, vagões, etc). <br /> <br />

                            Libera as cancelas, os totens, portais, semáforos, iluminação. Automatizando todo 
                            processo com segurança e eficiência.
                        </p>
                    </li>
                    <li>
                        <h2 className="autoGates-tituloProd">Scanner</h2>
                        <p>
                             Proporciona o efetivo controle da via, o local onde o veículo está, o motorista. 
                             Se o veículo está em movimento. Com esta tecnologia virtual, é possível ter total 
                             controle da via e do processo sem a utilização de sensores ou laços físicos. Tudo 
                             para uma melhor segurança e automação de permissão. Tudo é feito em conjunto com o 
                             software, de forma automática e rápida, para uma melhor entrada.
                        </p>
                    </li>
                </ul>
            </section>
        );
    }
}
 
export default AutoGates;