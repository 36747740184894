import React, { Component } from "react";
import {Link} from 'react-router-dom';
import "./style.css";
import logo from "./imgs/sattra-logo.svg"

class NavBar extends Component {
  state= {
    isSwitchOn: false,
  }

  render() {
    return (
      <section className="navbar-itens">
        <a href="/" className="navbar-home"><img className="navbar-logo" src={logo} alt="Logo"/></a>
        <button className="menu-abrir" onClick={ () => this.setState({isSwitchOn: true})}>Abre Menu</button>
        
        <div className={this.state.isSwitchOn ? "navbar-mobilebg" : ""} onClick={ () => this.setState({isSwitchOn: false})}></div>
        <div className={this.state.isSwitchOn ? "navbar-mobileAtivo" : "navbar-mobile"}>
          <button className="menu-fechar" onClick={ () => this.setState({isSwitchOn: false})}>Fecha Menu</button>
          <ul className="navbar-menu">
            <li>
              <Link className="navbar-apresentacoes" to="/apresentacoes-desenhos">Apresentações e Desenhos</Link>
            </li>
            <li>
              <div className="navbar-produtos">
                <Link to="/produtos">Produtos</Link>
                {/* <ul className="navbar-mostrar">
                  <li>
                    <Link to="/produtos#controleAcesso" className="navbar-maiorTexto">Controle de Acesso</Link>
                  </li>
                  <li>
                    <Link to="/produtos#ocr-lpr"></Link>
                  </li>
                </ul> */}
              </div>
            </li>
            <li>
              <div className="navbar-facial">
                <Link to="/facial">Facial</Link>
                {/* <ul className="navbar-mostrar">
                  <li>
                    <Link to="/produtos#controleAcesso" className="navbar-maiorTexto">Controle de Acesso</Link>
                  </li>
                  <li>
                    <Link to="/produtos#ocr-lpr"></Link>
                  </li>
                </ul> */}
              </div>
            </li>
            <li>
              <div className="navbar-servicos">
                <Link to="/servicos">Serviços</Link>
                {/* <a href="/servicos">Serviços</a> */}
              </div>
            </li>
            <li>
              <Link to="/sobre">Sobre</Link>
              {/* <a href="/sobre">Sobre</a> */}
            </li>
            <li>
              <Link to="/fale-conosco">Fale Conosco</Link>
              {/* <a href="/fale-conosco">Fale Conosco</a> */}
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default NavBar;
