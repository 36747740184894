import React, { Component } from "react";
import "./style.css";

class Footer extends Component {
  render() {
    return (
      <section className="footer">
        <p className="copyright">
          &copy; Copyright: Sattra soluções em tecnologia LTDA - 2020
        </p>
      </section>
    );
  }
}

export default Footer;
