import React, { Component } from 'react';
import ControlePessoas from './ControlePessoas/';
import ControleVeiculos from './ControleVeiculos/';
import "./style.css";

class ControleAcesso extends Component {
    render() { 
        return ( 
            <div id="controleAcesso">
                <h1 className="conAcesso-titulo">Soluções para Controle de Acesso</h1>
                <ControlePessoas />
                <ControleVeiculos />
            </div>
         );
    }
}
 
export default ControleAcesso;