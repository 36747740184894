import React, { Component } from 'react';
import "./style.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import im1 from "../../../assets/imgs/produtos/catraca1.jpg";
import im2 from "../../../assets/imgs/produtos/catraca2.png";
import im3 from "../../../assets/imgs/produtos/torn1.png";
import im4 from "../../../assets/imgs/produtos/torn2.png";
import im5 from "../../../assets/imgs/produtos/pne1.jpg";
import im6 from "../../../assets/imgs/produtos/pne2.jpg";
import im7 from "../../../assets/imgs/produtos/portas1.png";
import im8 from "../../../assets/imgs/produtos/cracha1.png";
import im9 from "../../../assets/imgs/produtos/biometrias1.png";



const images = [
    {
      original: im2,
      thumbnail: im2,
    },
    {
      original: im1,
      thumbnail: im1,
    },
];

const images2 = [
    {
      original: im3,
      thumbnail: im3,
    },
    {
      original: im4,
      thumbnail: im4,
    },
];

const images3 = [
    {
      original: im5,
      thumbnail: im5,
    },
    {
      original: im6,
      thumbnail: im6,
    },
];

const images4 = [
    {
      original: im7,
      thumbnail: im7,
    },
];

const images5 = [
    {
      original: im8,
      thumbnail: im8,
    },
];

const images6 = [
    {
      original: im9,
      thumbnail: im9,
    },
];

class ControlePessoas extends Component {
    render() { 
        return ( 
            <section className="conPessoas">
                <ul>
                    <h2 className="conPessoas-tituloPrinc">Pessoas</h2>
                    <li>
                        <h2 className="conPessoas-tituloProd">Catracas</h2>
                        <ImageGallery items={images} showThumbnails={false} showPlayButton={false}/>
                        <p className=".conPessoas-texto-image">
                            A Catrax surgiu para fornecer segurança com controles de acesso de design robusto, 
                            aplicações versáteis e ideais para áreas de grande fluxo. Oferecendo soluções de 
                            alta responsabilidade que levam segurança, confiabilidade, funcionalidade que lidam 
                            com a vida a pessoas. A Catrax vai além da tecnologia.<br /> <br />

                            São cerca de 30 países que possuem soluções Catrax. Na América Latina, o México é 
                            o principal mercado, seguido por Colômbia e Peru, onde os controles de acesso 
                            marcam presença em prédios corporativos, bancos, casas de show, estádios, 
                            universidades, entre outras aplicações. A Catrax ainda conta com instalações na 
                            Europa, onde a Itália é o principal mercado, além da presença na Índia e em países 
                            do Oriente Médio.
                        </p>
                    </li>
                    <li>
                        <h2 className="conPessoas-tituloProd">Torniquetes</h2>
                        <ImageGallery items={images2} showThumbnails={false} showPlayButton={false}/>
                        <p className=".conPessoas-texto-image">
                            O torniquete para controle de acesso vem sendo cada vez mais utilizado em locais 
                            que necessitem realizar e organizar o controle do alto fluxo de acesso de pedestres 
                            como em estações de trens, rodoviárias, estádios de futebol, instituições de ensino, 
                            clubes, parques de diversão, piscinas e demais locais que precisem efetuar um 
                            controle de acesso mais rigoroso e individual dos usuários que acessam o local. <br /> <br />

                            Há possibilidade também de realizar a integração do torniquete com leitor de 
                            biometria, leitor de proximidade, teclados numéricos, urnas recolhedoras de cartão, 
                            entre outros equipamentos que auxiliam na restrição do acesso de pessoas não 
                            autorizadas o que aumenta a segurança do local.
                        </p>
                    </li>
                    <li>
                        <h2 className="conPessoas-tituloProd">PNE</h2>
                        <ImageGallery items={images3} showThumbnails={false} showPlayButton={false}/>
                        <p className=".conPessoas-texto-image">
                            A Sattra preza por acessibilidade para todos os tipos de pessoas. Com essa mentalidade 
                            nossa empresa. Dispõe de catracas, torniquetes, entre outros produtos, que contém auxílio 
                            ao portador de necessidades especiais. Como por exemplo catracas produzidas especialmente 
                            pensadas para cadeirantes. <br /><br />

                            Com maior espaçamento, facilitando a autorização do acesso. Portões com movimentos diversificados e outras situações.
                        </p>
                    </li>
                    <li>
                        <h2 className="conPessoas-tituloProd">Controladoras de Portas</h2>
                        <ImageGallery items={images4} showPlayButton={false} showThumbnails={false} />
                        <p className=".conPessoas-texto-image">
                            Terminal sem display, teclado e gabinete, a MCA Painel pode ser instalada em local 
                            não aparente sendo conectada apenas as leitoras e dispositivos de acionamento, como 
                            o controle de cancelas e acesso restrito a portas com instalação apenas das leitoras. <br /> <br />
                            
                            Solução compatível com os cartões de identificação mais utilizados no mercado, incluindo RFID, tarja 
                            magnética, código de barras e a tecnologia padrão MIFARE.
                        </p>
                    </li>
                    <li>
                        <h2 className="conPessoas-tituloProd">Estação de Cadastramento</h2>
                        <p>
                            Solicita os dados da pessoa. Liberando o acesso da mesma ao local.
                        </p>
                    </li>
                    <li>
                        <h2 className="conPessoas-tituloProd">Biometrias</h2>
                        <ImageGallery items={images6} showPlayButton={false} showThumbnails={false} />
                        <p className=".conPessoas-texto-image">
                            Equipamento para controle de acesso versátil. Utiliza tecnologias como
                            leitor biométrico, teclado sensível ao toque, cartões Mifare, RFID e Barras, podendo
                            trabalhar em rede ou isolado. Permite conexão de pendrive e alimentação PoE.
                        </p>
                    </li>
                    <li>
                        <h2 className="conPessoas-tituloProd">Leitoras para Crachás</h2>
                        <ImageGallery items={images5} showPlayButton={false} showThumbnails={false} />
                        <p className=".conPessoas-texto-image">
                            Tecnologia desenvolvida para atender uma grande variedade e complexidade de projetos de controle de acesso, possibilitando 
                            o acionamento de diversos periféricos. Solução compatível com os cartões de identificação mais utilizados no mercado, incluindo 
                            RFID, tarja magnética, código de barras e a tecnologia padrão MIFARE.
                        </p>
                    </li>
                </ul>
            </section>
         );
    }
}
 
export default ControlePessoas;