import React, { useState } from 'react';
import "./style.css";
import axios from 'axios';

function FaleConosco () {
    const [campos, setCampos] = useState({
        nome: '',
        telefone: '',
        email: '',
        assunto: '',
        mensagem: '',
        contato: 'email',
    });

    function handleInputChange(event){
        campos[event.target.name] = event.target.value;
        setCampos(campos);
    }

    function handleFormSubmit(event){
        event.preventDefault();
        console.log(campos);
        const enviar = {
            nome: campos.nome,
            telefone: campos.telefone,
            email: campos.email,
            assunto: campos.assunto,
            mensagem: campos.mensagem,
            contato: campos.contato
        };

        axios.post(`http://sattra.com.br/#/send/`, { enviar })
            .then(res => {
              console.log(res);
              console.log(res.data);
        })
    }


    return (
        <div className="fale-conosco">
            <form onSubmit={handleFormSubmit}>
                <div className="fale-secao">
                    <label htmlFor="nome">Nome e sobrenome</label>
                    <input type="text" id="nome" name="nome" className="input-padrao" required placeholder="João da Silva" onChange={handleInputChange} />
                </div>

                <div className="fale-secao">
                    <label htmlFor="telefone">Telefone</label>
                    <input type="tel" id="telefone" name="telefone" className="input-padrao" required placeholder="(XX) XXXXX-XXXX"  onChange={handleInputChange}/>
                </div>

                <div className="fale-secao">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" className="input-padrao" required placeholder="seuemail@dominio.com" onChange={handleInputChange} />
                </div>

                <div className="fale-secao">
                    <label htmlFor="assunto">Assunto</label>
                    <input type="text" id="assunto" name="assunto" className="input-padrao" required placeholder="Sattra" onChange={handleInputChange} />
                </div>

                <div className="fale-mensagem">
                    <label htmlFor="mensagem">Mensagem</label>
                    <textarea cols="70" rows="10" id="mensagem" name="mensagem" className="input-padraoMensagem" required onChange={handleInputChange} />
                </div>

                <fieldset >
				    <legend>Como prefere o nosso contato?</legend>
                    <div className="fale-radio">
				    <label htmlFor="contato"><input type="radio" name="contato" value="email" id="radio-email" checked onChange={handleInputChange}/> Email</label>

				    <label htmlFor="contato"><input type="radio" name="contato" value="telefone" id="radio-telefone" onChange={handleInputChange}/> Telefone</label>

				    <label htmlFor="contato"><input type="radio" name="contato" value="whatsapp" id="radio-whatsapp" onChange={handleInputChange}/> WhatsApp</label>
                    </div>
			    </fieldset>

                <input type="submit" value="Enviar formulário" className="enviar" />
            </form>

            <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css" />
            <a href="https://wa.me/55047992035326?text=Ol%C3%A1%2C+tudo+bem%3F+Vi+o+contato+pelo+site+da+Sattra.+Gostaria+de+saber+%28escreva+sua+duvida+aqui%21%29." className="float" target="_blank" >
                <i className="fa fa-whatsapp my-float"></i>
            </a>
        </div>
    );
}
 
export default FaleConosco;