import React from 'react';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Servico from '../../components/Servico';

function Servicos () {
    return (
        <section>
            <NavBar />
            <Servico />
            <Footer />
        </section>
    );
}

export default Servicos;