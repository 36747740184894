import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import "./style.css";
import i1 from "../../../assets/imgs/produtos/Totens.png";
import i2 from "../../../assets/imgs/produtos/cancela1.jpg";

const images = [
    {
      original: i1,
      thumbnail: i1,
    },
];

const images2 = [
    {
      original: i2,
      thumbnail: i2,
    },
];

class ControleVeiculos extends Component {
    render() { 
        return (
            <section className="conVeiculos">
                <ul>
                    <h2 className="conVeiculos-tituloPrinc">Veículos</h2>
                    <li>
                        <h2 className="conVeiculos-tituloProd">Totens</h2>
                        <ImageGallery items={images} showPlayButton={false} showThumbnails={false} />
                        <p className="conVeiculos-texto-image">
                            Para maior controle e segurança, o totem contém controle preciso de acesso e permanência. Câmeras para segurança, e identificação 
                            do condutor. Possuindo assistência remota para o condutor. Leitura de código de barras 1D2D, cesto de lixo integrado.
                        </p>
                    </li>
                    <li>
                        <h2 className="conVeiculos-tituloProd">Cancelas</h2>
                        <ImageGallery items={images2} showPlayButton={false} showThumbnails={false} />
                        <p className="conVeiculos-texto-image">
                            As cancelas são um ótimo meio para controle de fluxo e também de acesso. possuindo 
                            motores brushless sem escovas, várias interfaces de IO. Sensores de temperatura e 
                            umidade, telemetria, Comunicação etherenet e serial, juntamente com diversos modelos 
                            de braços.
                        </p>
                    </li>
                    <li>
                        <h2 className="conVeiculos-tituloProd">Semáforos</h2>
                        <p>
                            Funciona igual o semáforo de trânsito. Utilizado para maior controle de tráfico. Possibilitando alterar o tempo de espera de 
                            cada veículo. Funciona em complemento com as cancelas, abrindo e fechando elas automaticamente.
                        </p>
                    </li>
                    <li>
                        <h2 className="conVeiculos-tituloProd">Automação</h2>
                        <p>
                            Funcionamento em conjunto com periféricos integrando-os e controlando-os via 
                            software facilitando e agilizando a passagem pelo processo. Fazendo todos os 
                            procedimentos de controle de acesso de forma automática. Obtêm o peso, verifica a 
                            autorização de acesso através dos controles, abre a cancela e libera o acesso com 
                            redução de custos operacionais e automaticamente agilizando todo processo.
                        </p>
                    </li>
                </ul>
            </section>
        );
    }
}
 
export default ControleVeiculos;