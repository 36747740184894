import React, { Component } from 'react';
import AutoGates from './AutoGates';
import "./style.css";

class OCR_LPR extends Component {
    render() { 
        return (
            <div id="ocr-lpr">
                <h1 className="ocr-lpr-titulo">Soluções OCR e LPR</h1>
                <AutoGates />
            </div>
        );
    }
}
 
export default OCR_LPR;