import React from 'react';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import Sobre from '../../components/Sobre';

function sobre () {
    return (
        <section>
            <NavBar />
            <Sobre />
            <Footer />
        </section>
    );
}

export default sobre;