import React from 'react';
import ControleAcesso from '../../components/Controle de Acesso';
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import OCR_LPR from '../../components/OCR_LPR';


function Produtos() {
    return(
        <section>
            <NavBar />
            <ControleAcesso />
            <OCR_LPR />
            <Footer />
        </section>
    );

}

export default Produtos;