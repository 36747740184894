import React from 'react';
import FaleConosco from '../../components/FaleConosco';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';

function fale_conosco () {
    return (
        <section>
            <NavBar />
            <FaleConosco />
            <Footer />
        </section>
    );
}

export default fale_conosco;