import React, { Component } from 'react';
import "./style.css";
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import im1 from "../../assets/imgs/Facial/Facial1.png";
import im2 from "../../assets/imgs/Facial/Facial2.png";
import im3 from "../../assets/imgs/Facial/Facial3.png";

const images = [
    {
      original: im1,
      thumbnail: im1,
    },
    {
      original: im2,
      thumbnail: im2,
    },
    {
      original: im3,
      thumbnail: im3,
    },
];

class Facial extends Component {
    render() { 
        return (
            <section id="facial" className="conPessoas facial">
                <h1 className="facial-titulo">Sistema Facial</h1>
                <ul>
                    <li>
                        <ImageGallery items={images} showThumbnails={false} showPlayButton={false}/>
                        <p className=".conPessoas-texto-image">
                            O Sistema Facial, foi criado para melhorar o acesso de pessoas nas instalações. Tornando
                            tudo muito mais prático e rápido.<br /> <br />

                            Fazendo a conexão com os aparelhos através de nosso sistema.<br /> <br />

                            Mede a temperatura da pessoa, com váriação de 0.5 °C. Possui uma tela touch scrren LCD.
                            Reconhece a pessoa de máscara. Percebe a presença de uma pessoa em até 3 metros de distância.
                            <br /> <br />

                            Nosso sistema possui uma taxa de 99% de reconhecimento facial, em até 0.2 segundos.
                            <br /> <br />

                            Podendo ser utilizado junto com:
                        </p>
                        <div className="facial-lista">
                            • Catraca;<br/>
                            • Torniquetes;<br/>
                            • PNE;<br/>
                            • Controladoras de Portas;<br/>
                            • Totens;<br/>
                            • Cancelas;<br/>
                            • Entre outros.<br/>
                        </div>
                    </li>
                </ul>
            </section>
        );
    }
}
 
export default Facial;