import React from 'react';
import Footer from "../../components/Footer";
import NavBar from "../../components/NavBar";
import Facial from '../../components/Facial/Facial'


function PFacial() {
    return(
        <section>
            <NavBar />
            <Facial />
            <Footer />
        </section>
    );

}

export default PFacial;